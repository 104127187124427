import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '33, 33, 33',
		'primary-dark': '0, 0, 0',
		'accent': '255, 214, 0',
		'accent-plus': '7, 7, 7',
	},
});
